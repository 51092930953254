import * as React from "react";
import logoPath from "./logo512.png";
import PropTypes from "prop-types";
import NavigationBar from "./NavigationBar";

const Header = ({ siteTitle }) => (
  <header
    style={{
      zIndex: 1,
      position:"relative",
      textDecoration: 'none',
      cursor: "crosshair",
      color:"white",
        font: 'Nunito',
        fontWeight: 600,
        flexGrow: 1,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}>
        <div 
        style={{
            position: "absolute",
            left: 0,
            margin: 24
        }}>
        <img src={logoPath} width={69} height={69} alt="Circlular Phantom Baron Logo"></img>
    </div>
      <h1 style={{ margin: 0 }}>
          {siteTitle}
      </h1>
      <NavigationBar></NavigationBar>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
