import {useParams} from 'react-router-dom';
import usePaymentFetch from './usePaymentFetch';

const PaymentDetails = ({data, isPending, error}) => {
    const {id} = useParams();
    const baron = data[(id-1)]
    const {paymentInfoData, paymentInfoIsPending, paymentInfoError} = usePaymentFetch(process.env.REACT_APP_FIREBASE_PAY_ENDPOINT + id);
    return (
        <div className="mint-info">
            <div className='mint-list'>
                <h1>Transaction Awaiting...</h1>
                <div>
                    {baron && <p>Phantom Baron {baron.id.padStart(5, '0')} Reserved</p>}
                    { error && <div>{error}</div> }
                    { isPending && <div>Loading...</div> }
                    {baron && <div className="zoom-within-container-pay">
                        <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + baron.ipfshash} height="250px" loading="lazy" alt={"Phantom Baron " + baron.id}></img>
                    </div>}
                    {baron && <p>Token is reserved for 1 hour.</p>}
                </div>
                <div>
                {paymentInfoIsPending && <div>Loading...</div>}
                {paymentInfoError && <div>{paymentInfoError}</div>}
                {paymentInfoData && <h4><span className="blue">Ada to Send: </span>{paymentInfoData.adaToSend}</h4>}
                {paymentInfoData && <h4><span className="blue">Address: </span>{paymentInfoData.paymentAddress} <button className='button' onClick={() => {navigator.clipboard.writeText(paymentInfoData.paymentAddress)}}>Copy Address</button></h4>}
                {paymentInfoData && <h4><span className="blue">Expires: </span>{paymentInfoData.expires}</h4>}
                {paymentInfoData && paymentInfoData.debug && <h4><span className="blue">Debug: </span>{paymentInfoData.debug}</h4>}
                {paymentInfoData && <h4><span className="blue">Price in Euros: </span>{paymentInfoData.priceInEur}</h4>}
                {paymentInfoData && <h4><span className="blue">Price in $USD: </span>{paymentInfoData.priceInUsd}</h4>}
                {paymentInfoData && <h4><span className="blue">Price in JPY: </span>{paymentInfoData.priceInJpy}</h4>}
                {paymentInfoData && <h4><span className="blue">Price in BTC: </span>{paymentInfoData.priceInBtc}</h4>}
                {paymentInfoData && <h4><span className="blue">Effective Date: </span>{paymentInfoData.effectivedate}</h4>}
                <span className="blue">Do not refresh page, if page is blank then the token is reserved. </span>
                <span className="blue">Please find another phantom or check back in 1 hour.</span><br></br>
                <span>Please allow ~30 minutes for token to arrive in wallet following a successful transaction.</span><br></br>
                </div>
            </div>
            <div className='banner'></div>
        </div>
    )
}

export default PaymentDetails;