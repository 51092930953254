const About = () => {
    
        return (
            <div className="about">
                <div className="main-content">
                    <h1>Story</h1>
                    <article>
                        The <span className="blue">Phantom Baron</span> was a man of many lives.. A member of the aristocracy and known for his refined taste in fashion, the <span className="blue">Baron Van Golderf Ponthes</span> traveled the world in search of the perfect outfit. From the beaches of Barbados to the den of mount Vesuvius in Italy, no places were too far in his quest for the perfect outfit, the one outfit that would open him the door to the famous and mysterious society, <span className="blue">“Black Pegasus”</span>, the highest institution within the fashion community. After years of relentless efforts and searching, it was deep inside the Cardano Caverns that he finally found it, the outfit standing at the pinnacle of <span className="blue">“le bon goût”</span>, praised by any fashionista worthy of the name, the outfit of them all, the one and only <span className="blue">“Crystal Ice suit”</span> made from the scales of the ice dragon <span className="blue">“Kuruokami”</span>. Now welcomed with open arms by Black Pegasus, the <span className="blue">Baron Van Golderf Ponthes</span> had finally obtained everything he ever wanted: wealth, fame, and power! However, sometimes things are not what they seem… Betrayed by the very own society he dedicated his life to join, the Baron Van Golderf Ponthes fled for his life abandoning the <span className="blue">“Kuruokami suit”</span> and all the treasures he has accumulated throughout his life. However, before disappearing into the blockchain void, the <span className="blue">Phantom Baron</span> made one final promise that he would return to reclaim what was rightfully his… <span className="blue">“The Phantom Baron Collection”</span>
                        <br></br>
                    </article>
                    <h1>Collection</h1>
                    <article>
                    The <span className="blue">Phantom Barons NFT collection </span> lives on the <span className="blue">Cardano blockchain</span> consisting of <span className="blue">10,000 unique</span> algorithmically assembled aristocrats. Utilizing Cardano's multi asset ledger for NFTs alleviates the pressures of gas prices. <br></br><br></br>Future plans include implementing Plutus validators to enable a market place and use generated funds to start a stake pool.
                    </article>
                </div>
                <div className="extra-pad">
                    <h1>How to Buy</h1>
                    <div className="rarity-list">
                        <div><span className="extra-pad blue">PLEASE DO NOT </span><span className="extra-pad">send ADA directly from an exchange as this will cause the transaction to fail, please use a Cardano Wallet such as Yoroi or Daedalus.</span></div>
                        <div><span className="extra-pad">After pressing the button, the address is reserved for 1 hour </span><span className="extra-pad blue">ONLY, PLEASE DO NOT</span><span className="extra-pad"> send ADA to the address after this time.</span></div>
                        <div><span className="extra-pad">Due to the nature of the blockchain, any mistakes made with the purchase cannot be rectified, once the funds are sent, they are gone forever and cannot be returned.</span><span className="extra-pad blue"> PLEASE ENSURE THE ADDRESS IS CORRECT.</span></div>
                        <div className="extra-pad">Purchase specific Phantom Baron tokens from the <span className="extra-pad blue">Explore</span> page. Click a baron, click the Mint NFT button, confirm the token reservation, then send 15 ADA to the specified address to recieve the specific Phantom Baron token.</div>
                        <div className="extra-pad">Purchase a random Phantom Baron using the Pay-In address on the <span className="extra-pad blue">Home</span> page. Send 15 ADA to recieve 1 random Phantom Baron NFT.</div>
                        <div className="extra-pad">Send X times 15 ADA to the Pay-In address below or on the <span className="extra-pad blue">Home</span> page and recieve X random Phantom Barons NFTs (Max 20 per Transaction)</div>
                    </div>
                    <span className="extra-pad">
                        <h4>Smart Contract Pay-In Address: <span className="blue">addr1vxuz84g5jygkj7ea5xpzjsslg99ezrc8yc6azsgsxh7fjvq9eu9sc </span><button className="button" onClick={() => {navigator.clipboard.writeText("addr1vxuz84g5jygkj7ea5xpzjsslg99ezrc8yc6azsgsxh7fjvq9eu9sc")}}>Copy Address</button></h4>
                    </span>
                </div>
                <article>
                <div><span className="extra-pad blue">Please allow some time after purchasing, around 30 minutes after a successful transaction for the token to appear.</span></div>
                </article>
                <br></br>
                <div><img src="/powered_by.png" height="124px" loading="lazy" alt="Phantom Barons NFT collection powered by Cardano Blockchain"></img></div>
            </div>
        )
    }
    
    export default About;