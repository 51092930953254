import React from 'react';
import { SocialIcon } from 'react-social-icons';
import {makeStyles} from '@material-ui/core/styles';
import {Toolbar, Typography} from '@material-ui/core';

const styles = makeStyles({
    bar: {
        paddingTop: "0.42rem",
        paddingBottom: "1.00rem",
        background: `#21252b`,
        backgroundColor: "#21252b",
        width: "100%",
        contentAlign: "center",
        cursor: "crosshair",
    },
    menuItem: {
        color:"white",
        fontFamily: 'Nunito',
        fontWeight: 600,
        fontSize:"0.75em",
        flexGrow: 1,
        "&:hover": {
            color: "rgba(176,244,254,1)"
        },
    },
})

function FooterBar(){
    const classes = styles();

    return (
        <Toolbar position="sticky" color="white" className={classes.bar}>            
            <Typography variant="h6" className={classes.menuItem}>
                <SocialIcon url="https://twitter.com/PhantomBarons" target="_blank"/>
            </Typography>
            <Typography variant="h6" className={classes.menuItem}>
                <span>₳ The Phantom Barons NFT Collection ₳</span>
                <br></br>
                <span>Policy ID: e85d00bfedb7effd113f15e9ed5868ec40f1e0786ee49b24ecc1e50a</span>                
            </Typography>
            <Typography variant="h6" className={classes.menuItem}>
                <SocialIcon url="https://www.reddit.com/user/PhantomBaronsNFT/" target="_blank"/>
            </Typography>
        </Toolbar>
    )
}

export default FooterBar