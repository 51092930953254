import Particles from "react-tsparticles";
import { Main } from "tsparticles";
import { loadStarsPreset } from "tsparticles-preset-stars";
import React from 'react';

export class ParticlesContainer extends React.PureComponent {
  // this customizes the component tsParticles installation
  customInit(main: Main) {
    // this adds the preset to tsParticles, you can safely use the
    loadStarsPreset(main);
  }

  render() {
    const options = {
      preset: "stars",
    };

    return <Particles options={options} init={this.customInit} />;
  }
}


export default ParticlesContainer;