import RarityList from "./RarityList";

const Rarity = () => {
    
    return (
        <div className="rarity">
            <div className="main-content">
                <RarityList></RarityList>
                <br></br>
            </div>
        </div>
    )
}

export default Rarity;