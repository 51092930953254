import CustomMintButton from "./CustomMintButton";
import { useParams, Link } from "react-router-dom";
import useBaronDetailFetch from './useBaronDetailFetch';

const BaronDetails = ({data, isPending, error}) => {
    const {id} = useParams();
    const baron = data[(id-1)]
    const {baronDetailData, baronDetailIsPending, baronDetailError} = useBaronDetailFetch(process.env.REACT_APP_FIREBASE_DETAIL_ENDPOINT + id);

    return (
        <div className="baron-page">
            {isPending && <div>Loading...</div>}
            {error && <div>{error}</div>}
            { baron &&
                <article>
                    <h1>{baron.name}</h1>
                    <div className="zoom-within-container">
                        <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + baron.ipfshash} height="500px" loading="lazy" alt={"Phantom Baron " + baron.id}></img>
                    </div>
                    <Link to={`/explore/${baron.id}/mint`} style={{ textDecoration: 'none' }}>
                    {baronDetailData && baronDetailData.state==="free" && <CustomMintButton txt="Mint NFT" id={baron.id}></CustomMintButton>}
                    </Link>
                    {baronDetailError && <div className="baron-details extra-pad">
                        <span className="blue">{baronDetailError}</span>
                    </div>}
                    {baronDetailIsPending && <div className="baron-details extra-pad">
                    <span>Status:    </span><span className="blue">Loading...</span>
                    </div>}
                    {baronDetailData && <div className="baron-details extra-pad">
                        <span>Status:    </span>
                        <span className="blue">{baronDetailData.state.toUpperCase()}</span>
                    </div>}
                    <div className="baron-details">
                        <span>Background:    </span>
                        <span className="blue">{baron.Background}</span>
                    </div>
                    <div className="baron-details">
                        <span>Hat:    </span>
                        <span className="blue">{baron.Hat}</span>
                    </div>
                    <div className="baron-details">
                        <span>Monical:    </span>
                        <span className="blue">{baron.Monical}</span>
                    </div>
                    <div className="baron-details">
                        <span>Mustache:    </span>
                        <span className="blue">{baron.Mustache}</span>
                    </div>
                    <div className="baron-details">
                        <span>Bow:    </span>
                        <span className="blue">{baron.Bow}</span>
                    </div>
                    <div className="baron-details">
                        <span>Jacket:    </span>
                        <span className="blue">{baron.Jacket}</span>
                    </div>
                    <div className="baron-details">
                        <span>Belt:    </span>
                        <span className="blue">{baron.Belt}</span>
                    </div>
                    <div className="baron-details">
                        <span>Pants:    </span>
                        <span className="blue">{baron.Pants}</span>
                    </div>
                    <div className="baron-details">
                        <span>Shoes:    </span>
                        <span className="blue">{baron.Shoes}</span>
                    </div>
                    <div className="baron-details">
                        <span>Cane:    </span>
                        <span className="blue">{baron.Cane}</span>
                    </div>
                    <div className="baron-details">
                        <span>Accents:    </span>
                        <span className="blue">{baron.Accents}</span>
                    </div>
                    <h4>Phantom baron aristocrat enjoying life of high society.</h4>
                    <div className="extra-pad"></div>
                </article>
            }
        </div>
    )
}

export default BaronDetails;