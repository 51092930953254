import PageBar from './PageBar';
import ExploreList from './ExploreList';
import { useState, useEffect } from 'react';

const Explore = ({data, isPending, error, countData, countIsPending, countError}) => {
    const itemsPerPage = 1000;
    const [displayData, setDisplayData] = useState(null);
    const [startSlice, setStartSlice] = useState(0);
    const [endSlice, setEndSlice] = useState(1000);

    const handlePageClick = (txt) => {
        setStartSlice((txt - 1)*itemsPerPage)
        setEndSlice(txt*itemsPerPage)
    }
    
    useEffect(() => {
        if (data) {
        setDisplayData(data.slice(startSlice,endSlice))
        } else {
            setDisplayData(data)
        }
    }, [data, startSlice, endSlice]);

    return (
        <div className="explore">
            { countError && <div>{countError}</div>}
            { countIsPending && <div>Loading...</div> }
            {countData && <h3><span>Total Phantom Barons: </span><span className="blue">{countData.nftTotal}</span><span> Sold: </span><span className="blue">{countData.sold}</span><span> Reserved: </span><span className="blue">{countData.reserved}</span><span> Available: </span><span className="blue">{countData.free}</span></h3>}
            <PageBar handlePageClick={handlePageClick}></PageBar>
            { error && <div>{error}</div>}
            { isPending && <div>Loading...</div> }
            {displayData && <ExploreList barons={displayData}></ExploreList>}
            <PageBar handlePageClick={handlePageClick}></PageBar>
        </div>
    );
}

export default Explore;