import React from 'react';
import PageButton from "./PageButton";
import {makeStyles} from '@material-ui/core/styles';
import {Toolbar, Typography} from '@material-ui/core';

const styles = makeStyles({
    bar: {
        marginTop: "0.69rem",
        marginBottom: "0.69rem",
    },
    menuItem: {
        cursor:"pointer",
        color:"white",
        font: 'Nunito',
        fontWeight: 400,
        width:"1%",
        flexGrow: 1,
        "&:hover": {
            color: "rgba(176,244,254,1)"
        },
    },
})

function PageBar({handlePageClick}){
    const classes = styles();

    return (
        <Toolbar position="sticky" color="white" className={classes.bar}>
            <Typography variant="h6" className={classes.menuItem}>
                <PageButton handlePageClick={handlePageClick} txt="1"></PageButton>
                </Typography> 
            <Typography variant="h6" className={classes.menuItem}>
                <PageButton handlePageClick={handlePageClick} txt="2"></PageButton>
                </Typography> 
            <Typography variant="h6" className={classes.menuItem}>
                <PageButton handlePageClick={handlePageClick} txt="3"></PageButton>
                </Typography> 
            <Typography variant="h6" className={classes.menuItem}>
                <PageButton handlePageClick={handlePageClick} txt="4"></PageButton>
                </Typography> 
            <Typography variant="h6" className={classes.menuItem}>
                <PageButton handlePageClick={handlePageClick} txt="5"></PageButton>
                </Typography> 
            <Typography variant="h6" className={classes.menuItem}>
                <PageButton handlePageClick={handlePageClick} txt="6"></PageButton>
                </Typography> 
            <Typography variant="h6" className={classes.menuItem}>
                <PageButton handlePageClick={handlePageClick} txt="7"></PageButton>
                </Typography> 
            <Typography variant="h6" className={classes.menuItem}>
                <PageButton handlePageClick={handlePageClick} txt="8"></PageButton>
                </Typography> 
            <Typography variant="h6" className={classes.menuItem}>
                <PageButton handlePageClick={handlePageClick} txt="9"></PageButton>
                </Typography> 
            <Typography variant="h6" className={classes.menuItem}>
                <PageButton handlePageClick={handlePageClick} txt="10"></PageButton>
                </Typography> 
        </Toolbar>
    )
}

export default PageBar