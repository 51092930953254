export const RarityDataTitle = [
    {       'title': 'Background',
            'ice': 4.4,
            'desert': 5.2,
            'beach': 5.0,
            'fire': 5.6,
            'red': 9.5,
            'white': 9.7,
            'pink': 9.8,
            'yellow': 9.9,
            'blue': 10.0,
            'black': 10.1,
            'purple': 10.2,
            'green': 10.7,
        },
        {   'title': 'Jacket Type',
            'rare': 6.8,
            'transluscent': 19.6,
            'standard': 73.6,
        },
        {   'title': 'Jacket Color',
            'ice': 1.4,    
            'black fire': 1.6,    
            'white ice': 1.8,
            'fire': 2.0,
            'transluscent ice': 3.6,
            'white white': 4.4,
            'white black': 4.8,
            'black black': 4.8,
            'black white': 4.8,
            'blue orange': 4.9,
            'red black': 5.0,
            'transluscent fire': 5.1,
            'yellow blue': 5.1,
            'green purple': 5.2,
            'purple green': 5.3,
            'black red': 5.4,
            'black purple': 5.7,
            'black blue': 6.0,
            'black yellow': 6.0,
            'black green': 6.1,
            'standard': 10.8,
        },
        {   'title': 'Monical Type',
            'rare': 6.5,
            'standard': 93.5,
        },
        {   'title': 'Monical Color',
            'ice': 2.4,
            'fire': 4.2,
            'white': 7.8,
            'purple': 9.2,
            'yellow': 9.3,
            'red': 10.7,
            'blue': 16.5,
            'green': 19.1,
            'black': 20.9,
        },
        {'title': 'Mustache Type',
             'rare': 6.3,
             'standard': 93.7,
        },
        {'title': 'Mustache Color',
            'ice': 2.2,	 
            'fire': 4.1,
            'black': 93.7,
        },
        {'title': 'Bow Type',
             'rare': 6.2,
             'standard': 93.8,
        },
        {'title': 'Bow Color',
            'black black': 1.6,    
            'ice': 2.2,
            'fire': 4.0,	 
            'white': 7.1,
            'purple': 9.8,
            'yellow': 10.0,
            'red': 11.2,
            'blue': 17.1,
            'green': 17.8, 
            'black': 19.2,
        },
        {'title': 'Hat Type',
             'rare': 6.1,
             'standard': 93.9,
        },
        {   'title': 'Hat Color',
            'ice': 2.0,	 
            'white purple': 2.6,
            'white black': 3.8,
            'fire': 4.2,
            'black black': 7.1,
            'black yellow': 8.8,
            'black purple': 9.3,
            'black blue': 9.5,
            'black red': 9.6,
            'black white': 9.7,
            'black green': 14.5,
            'black': 18.9,
        },
        {'title': 'Belt Type',
             'rare': 6.6,
             'standard': 93.4,
        },
        {   'title': 'Belt Color', 
            'ice': 2.1,
            'fire': 4.5,
            'white': 7.2,
            'yellow': 8.9,
            'purple': 9.3,
            'red': 11.5,
            'blue': 16.8,
            'green': 18.5,
            'black': 21.2,
        },
        {   'title': 'Pants Type',
            'rare': 6.5,
            'transluscent': 19.6,
            'standard': 73.9,
        },
        {   'title': 'Pants Color',
            'black black': 1.5,    
            'ice': 2.2,
            'fire': 4.2,
            'white': 5.8,
            'yellow': 7.3,
            'purple': 7.9,
            'red': 8.4,
            'blue': 12.9,
            'green': 14.0,
            'black': 16.0,
            'standard': 19.6,
        },
        {   'title': 'Cane Type',
            'rare': 6.5,
            'transluscent': 19.6,
            'standard': 74.0,
        },
        {   'title': 'Cane Color',
            'black black': 1.6,    
            'ice': 2.0,
            'fire': 4.5,
            'yellow': 5.5,
            'purple': 5.6,
            'red': 8.4,
            'white': 9.5,
            'blue': 13.0,
            'green': 14.1,
            'black': 16.1,
            'standard': 19.6,
        },
        {   'title': 'Shoe Color',
            'black black': 1.5,    
            'ice': 2.3,
            'fire': 4.6,
            'white': 5.8,
            'yellow': 7.5,
            'purple': 7.5,
            'red': 8.7,
            'blue': 12.6,
            'green': 14.1,
            'black': 15.5,
            'standard': 20.0,
        },
        {'title': 'Shoe Type',
            'rare': 6.8,
            'transluscent': 20.0,
            'standard': 73.2,
        },
        {'title': 'Accent Type',
            'green money sign': 3.3,    
            'yellow money sign': 3.5, 
            'az pin': 3.6,
            'red ada pin': 4.9,
            'rose pin': 5.0,
            'blue ada pin': 5.1,
            'white ada pin': 5.3,
            'yellow ada pin': 5.4,
            'heart pin': 5.4,        
            'black ada eye': 8.6,
            'red ada eye': 8.7,
            'yellow ada eye': 8.9,
            'white ada eye': 9.3,
            'blue ada eye': 9.9,
            'standard': 13.1,
        },
        {'title': 'Flash Type',        
            'white flash': 6.8,
            'yellow flash': 11.7,
            'red flash': 16.1,
        },
    ];