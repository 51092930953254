import React from 'react'
import { RarityDataTitle } from "./RarityDataTitle";

const RarityList = () => {
    
    return (
        <div className="rarity-list">
        {RarityDataTitle.map((cat) => (
            <div className="rarity-preview" key={cat.title}>
                    <h2>{cat.title}</h2>
                    {Object.entries(cat).slice(1).map((item) => (
                            <div className='list-items' key={item[0]}>
                                <span>{item[0].toUpperCase()}: </span>
                                <span className="blue">{item[1]}%</span>
                            </div>
                    ))}
            </div> 
        ))}
        </div>
    )
}

export default RarityList;