import {useState, useEffect} from 'react';

const useDetailsFetch = (url) => {
    const [baronDetailData, setBaronDetailData] = useState(null);
    const [baronDetailIsPending, setBaronDetailIsPending] = useState(true);
    const [baronDetailError, setBaronDetailError] = useState(null);

    useEffect(() => {
        const abortCont =  new AbortController();
        fetch(url, {signal:abortCont.signal})
            .then(res => {
                if (!res.ok) {
                    throw Error("Could not fetch resource");
                }
                return res.json();
            })
            .then(baronDetailData => {
                setBaronDetailData(baronDetailData);
                setBaronDetailIsPending(false);
                setBaronDetailError(null);
            })
            .catch(err => {
                if(err.name === 'AbortError') {
                    console.log('');
                }
                else {
                    setBaronDetailIsPending(false);
                    setBaronDetailError(err.message);
                } 
            })
        return () => abortCont.abort();
    }, [url]);

    return {baronDetailData, baronDetailIsPending, baronDetailError};
}

export default useDetailsFetch;