import logoPath from "./logo512.png";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const Home = ({data, countData, countIsPending, countError}) => { // , countData, countIsPending, countError

    return (
        <div className="home">
            <div className="banner">
            <img src={logoPath} className="App-logo" alt="logo"></img>
            <br></br>
            <br></br>
            {/* <div>Current Cost per Baron: <span className="blue"> 15 ₳</span></div> */}
            { countError && <div>{countError}</div>}
            { countIsPending && <div>Loading...</div> }
            { countData && <div><span>Total Phantom Barons NFTs: </span><span className="blue">{countData.nftTotal}</span><span> Sold: </span><span className="blue">{countData.sold}</span><span> Reserved: </span><span className="blue">{countData.reserved}</span><span> Available: </span><span className="blue">{countData.free}</span></div>}
            {/* <div>Pay-In Address: <span className="blue">addr1vxuz84g5jygkj7ea5xpzjsslg99ezrc8yc6azsgsxh7fjvq9eu9sc </span><button className="button" onClick={() => {navigator.clipboard.writeText("addr1vxuz84g5jygkj7ea5xpzjsslg99ezrc8yc6azsgsxh7fjvq9eu9sc")}}>Copy Address</button></div>
            <h6>Send <span className="blue">X</span> times 15 ADA to the Pay-In address and mint <span className="blue">X</span> random Phantom Baron NFTs (Max 20 per Transaction)</h6>
            <h6><span className="blue">Please allow ~30 minutes for token to arrive in wallet following a successful transaction.</span></h6> */}
            <h5>Minting Policy Deadline: <span className="blue">8/23/2022</span></h5>
            <div className="slide-container">
            {data &&<Fade>
                <div className="each-fade">
                <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + data[(1346-1)].ipfshash} height="242px" alt="Barons Lineup 1"/>
                </div>
                <div className="each-fade">
                <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + data[(4202-1)].ipfshash} height="242px" alt="Barons Lineup 2"/>
                </div>
                <div className="each-fade">
                <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + data[(5524-1)].ipfshash} height="242px" alt="Barons Lineup 3"/>
                </div>
                <div className="each-fade">
                <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + data[(3-1)].ipfshash} height="242px" alt="Barons Lineup 4"/>
                </div>
                <div className="each-fade">
                <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + data[(6142-1)].ipfshash} height="242px" alt="Barons Lineup 5"/>
                </div>
                <div className="each-fade">
                <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + data[(5996-1)].ipfshash} height="242px" alt="Barons Lineup 6"/>
                </div>
                <div className="each-fade">
                <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + data[(233-1)].ipfshash} height="242px" alt="Barons Lineup 7"/>
                </div>
                <div className="each-fade">
                <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + data[(6119-1)].ipfshash} height="242px" alt="Barons Lineup 8"/>
                </div>
                <div className="each-fade">
                <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + data[(5832-1)].ipfshash} height="242px" alt="Barons Lineup 9"/>
                </div>
                <div className="each-fade">
                <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + data[(4441-1)].ipfshash} height="242px" alt="Barons Lineup 10"/>
                </div>
                <div className="each-fade">
                <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + data[(5987-1)].ipfshash} height="242px" alt="Barons Lineup 11"/>
                </div>
                <div className="each-fade">
                <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + data[(4202-1)].ipfshash} height="242px" alt="Barons Lineup 12"/>
                </div>
            </Fade>}
            </div>
            <div><img src="/powered_by.png" height="124px" loading="lazy" alt="Phantom Barons NFT collection powered by Cardano Blockchain"></img></div>
            </div>
        </div>
    )
}

export default Home;