import {useState, useEffect} from 'react';

const usePaymentFetch = (url) => {
    const [paymentInfoData, setPaymentInfoData] = useState(null);
    const [paymentInfoIsPending, setPaymentInfoIsPending] = useState(true);
    const [paymentInfoError, setPaymentInfoError] = useState(null);

    useEffect(() => {
        const abortCont =  new AbortController();
        fetch(url, {signal:abortCont.signal})
            .then(res => {
                if (!res.ok) {
                    throw Error("Could not fetch resource");
                }
                return res.json();
            })
            .then(paymentInfoData => {
                setPaymentInfoData(paymentInfoData);
                setPaymentInfoIsPending(false);
                setPaymentInfoError(null);
            })
            .catch(err => {
                if(err.name === 'AbortError') {
                    console.log('');
                }
                else {
                    setPaymentInfoIsPending(false);
                    setPaymentInfoError(err.message);
                }
            })
        return () => abortCont.abort();
    }, [url]);

    return {paymentInfoData, paymentInfoIsPending, paymentInfoError};
}

export default usePaymentFetch;