import React from 'react';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

const StyledButton = withStyles({
    root:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "44px",
        padding: "0 25px",
        boxSizing: "border-box",
        borderRadius: 0,
        background: "rgba(255,255,255,1)",
        color: "#21252b",
        fontFamily: 'Nunito',
        fontWeight: 600,
        transform: 'none',
        boxShadow: "6px 6px 0 0 #B0F4FE",
        transition: "backgroun .3s, border-color .3s, color .3s",
        "&:hover": {
            backgroundColor: "rgba(176,244,254,1)",
        },
    },
    label: {
        textTransform: 'capitalize'
    },
})(Button);

function CustomButton({txt, id, handleMintClick}){
    return(
        <Link to={`/explore/${id}`} style={{ textDecoration: 'none' }}>
            <StyledButton onClick={() => handleMintClick()} variant="contained">{txt}</StyledButton>
        </Link>
    )
}

export default CustomButton