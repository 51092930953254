import {Link} from 'react-router-dom';

const ExploreList = ({barons}) => {
    return (
        <div className="explore-list">
            {barons.map((baron) => ( 
                <div className="baron-preview" key={baron.id}>
                    <Link to={`/explore/${baron.id}`}>
                        <p>Phantom Baron</p>
                        <img src={'https://c-ipfs-gw.nmkr.io/ipfs/' + baron.ipfshash} height={"200px"} alt={"Phantom Baron " + baron.id} loading="lazy"></img>
                        <p>{baron.id.padStart(5, '0')}</p>
                    </Link> 
                </div>
            ))}
        </div>
    );
}

export default ExploreList