import React from 'react';
import {Button} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const StyledButton = withStyles({
    root:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "42px",
        width: "98%",
        padding: "0 25px",
        boxSizing: "border-box",
        borderRadius: 0,
        background: "rgba(33,37,43,1)",
        color: "rgba(255,255,255,1)",
        fontFamily: 'Nunito',
        fontWeight: 600,
        transform: 'none',
        boxShadow: "6px 6px 0 0 #B0F4FE",
        transition: "background .3s, border-color .3s, color .3s",
        "&:hover": {
            backgroundColor: "rgba(176,244,254,1)",
            color:"#21252b"
        },
    },
    label: {
        textTransform: 'capitalize'
    },
})(Button);

function PageButton({handlePageClick, txt}){
    return(
            <StyledButton onClick={() => handlePageClick(txt)} variant="contained">{txt}</StyledButton>
    )
}

export default PageButton