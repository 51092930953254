import './App.css';
import Header from "./header";
import Home from "./Home";
import Explore from "./Explore";
import Rarity from './Rarity';
import About from './About'
import FooterBar from './FooterBar';
import ParticlesContainer from './Particle';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import BaronDetails from './BaronDetails';
import useFetch from './useFetch';
import useDetailsFetch from './useDetailsFetch';
import MintDetails from './MintDetails';
import PaymentDetails from './PaymentDetails';

function App() {

  const {data, isPending, error} = useFetch(process.env.REACT_APP_FIREBASE_METADATA_ENDPOINT);
  const {countData, countIsPending, countError} = useDetailsFetch(process.env.REACT_APP_FIREBASE_COUNT_ENDPOINT);

  return (
    <Router>
      <div className="App">
        <div className="particles-back">
          <ParticlesContainer></ParticlesContainer>
        </div>
        <Header siteTitle={"Phantom Barons"} />
        <div className='content'>
          <Switch>
            <Route exact path='/'>
              <Home data={data} countData={countData} countIsPending={countIsPending} countError={countError}/> {/* countData={countData} countIsPending={countIsPending} countError={countError} */}
            </Route>
            <Route exact path='/explore'>
              <Explore data={data} isPending={isPending} error={error} countData={countData} countIsPending={countIsPending} countError={countError}/>
            </Route>
            <Route exact path='/about'>
              <About/>
            </Route>
            <Route exact path='/rarities'>
              <Rarity/>
            </Route>
            <Route exact path='/explore/:id/mint'>
              <MintDetails/>
            </Route>
            <Route exact path='/explore/:id/mint/reserve'>
              <PaymentDetails data={data} isPending={isPending} error={error}/>
            </Route>
            <Route exact path='/explore/:id'>
              {data && <BaronDetails data={data} isPending={isPending} error={error}/>}
            </Route>
          </Switch>
          <FooterBar siteTitle={"Phantom Barons"} />
        </div>
      </div>
    </Router>
  );
}

export default App;
