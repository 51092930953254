import {useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import CustomMintButton from './CustomMintButton';

const MintDetails = () => {
    const {id} = useParams();
    const [notClicked, setNotClicked] = useState(true);
    const handleMintClick = () => {
        setNotClicked(false)
    }

    return (
        <div className="mint-info">
            { notClicked && <div><h1>Confirm Reservation to Mint Phantom Baron {id}</h1>
            <h3 className="blue">Please copy and paste the address or thoroughly examine address before sending.</h3>
            <h5>This is the blockchain - I can do nothing to help recover funds.</h5>
            <h6>Please allow ~30 minutes for token to arrive in wallet following a successful transaction.</h6>
            <h6>Do not refresh the next page.</h6>
            <br></br>
            <Link to={`/explore/${id}/mint/reserve`} style={{ textDecoration: 'none' }}><CustomMintButton txt="Mint NFT" handleMintClick={handleMintClick} ></CustomMintButton></Link></div>}
            <div className='banner'></div>
        </div>
    )
}

export default MintDetails;