import {useState, useEffect} from 'react';

const useDetailsFetch = (url) => {
    const [countData, setCountData] = useState(null);
    const [countIsPending, setCountIsPending] = useState(true);
    const [countError, setCountError] = useState(null);

    useEffect(() => {
        const abortCont =  new AbortController();
        fetch(url, {signal:abortCont.signal})
            .then(res => {
                if (!res.ok) {
                    throw Error("Could not fetch resource");
                }
                return res.json();
            })
            .then(countData => {
                setCountData(countData);
                setCountIsPending(false);
                setCountError(null);
            })
            .catch(err => {
                if(err.name === 'AbortError') {
                    console.log('')
                }
                else {
                    setCountIsPending(false);
                    setCountError(err.message);
                }
            })
        return () => abortCont.abort();
    }, [url]);

    return {countData, countIsPending, countError};
}

export default useDetailsFetch;